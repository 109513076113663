
import { defineComponent, onMounted, ref } from "vue";
import PLogout from "@/components/PLogout.vue";
import { useSession } from "@/store/session.store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "PProfile",
  components: { PLogout },
  setup() {
    const user = ref({ name: "" });
    const sessionStore = useSession();
    const router = useRouter();

    onMounted(() => {
      try {
        user.value = JSON.parse(sessionStorage.getItem("SESSION") || "");
      } catch {
        //  PASS
      }
    });

    const logout = async () => {
      try {
        await sessionStore.logout();
        await router.push({ name: "login" });
      } catch {
        //  PASS
      }
    };

    return { user, logout };
  }
});
