
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useKids } from "@/store/kids.store";

import PKidList from "@/components/kids/PKidList.vue";
import PContainer from "@/components/PContainer.vue";
import PLogout from "@/components/PLogout.vue";
import PProfile from "@/components/PProfile.vue";

import UiSkeletonKidList from "@/components/ui/skeletons/UiSkeletonKidList.vue";

export default defineComponent({
  name: "PHeader",
  components: { UiSkeletonKidList, PLogout, PProfile, PContainer, PKidList },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const kids = useKids();

    const isHome = computed(() => route.name === "home");

    return {
      route,
      isHome,
      router,
      loading: kids.loading
    };
  }
});
