
import { defineComponent } from "vue";
import UiSkeletonTitle from "@/components/ui/skeletons/UiSkeletonTitle.vue";
import UiSkeletonSubtitle from "@/components/ui/skeletons/UiSkeletonSubtitle.vue";
import UiSkeletonText from "@/components/ui/skeletons/UiSkeletonText.vue";

export default defineComponent({
  name: "UiSkeletonKid",
  components: { UiSkeletonText, UiSkeletonSubtitle, UiSkeletonTitle }
});
