
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

import { useSession } from "@/store/session.store";

export default defineComponent({
  name: "PLogout",

  setup() {
    const sessionStore = useSession();
    const router = useRouter();

    const logout = async () => {
      try {
        await sessionStore.logout();
        await router.push({ name: "login" });
      } catch {
        //  PASS
      }
    };

    return { logout };
  }
});
