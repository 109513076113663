
import { defineComponent } from "vue";
import { useKids } from "@/store/kids.store";
import PKid from "@/components/kids/PKid.vue";

export default defineComponent({
  name: "PKidList",
  components: { PKid },

  setup() {
    const kidsStore = useKids();

    return {
      kids: kidsStore.kids
    };
  }
});
