
import { defineComponent, computed, PropType } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Kid } from "@/types/User";

export default defineComponent({
  name: "PKid",

  props: {
    kid: { type: Object as PropType<Kid>, required: true }
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const selected = computed(() => {
      return route.params.id == props.kid.id.toString();
    });

    const selectKid = () => {
      router.replace({ ...route, params: { id: props.kid.id } });
    };

    return { props, selected, selectKid };
  }
});
